.end-heading{
 text-align: center;
 margin: 20px 0;
 text-transform: uppercase;
}

.end-image{
    width: 90%;
    display: block;
    margin: auto;
    object-fit: contain;
    border-radius: 10px;
   
}

.end-points > h3{
    text-align: center;
    margin: 15px 0;
    font-size: 18px;
}
.end-points > ul > li{
    margin: 10px 5px;
    text-align: left;
}
.end-points > button{
    width: 90%;
    display: block;
    margin:10px auto;
    padding: 5px;
    border: 0;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bolder;
    background-color: rgb(92, 130, 253);
    color: white;
}
.end-icon{
  font-size: 18px !important;
  margin-bottom: 3px;
  margin-left: 5px;
 
}

.navigate-header{
    width: 95%;
    background-color: whitesmoke;
    height: 50px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.navigate-links{
    text-decoration: none;
     margin: 10px 20px;
     font-size: 18px;
     font-weight: bolder;
     color:black;
     text-transform: uppercase;
 }
 .ended-countdown-heading{
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  color: #F57C00;
 }
@media(min-width:375px){
    .end-points > h3{
        font-size: 20px;
    }
    .ended-countdown-heading{
      font-size: 18px;
    }
}

@media(min-width:425px){
    .end-points > h3{
        font-size: 22px;
    }
    .end-points > ul > li{
        font-size: 18px;
    }
}


@media(min-width:768px){
    .end-heading{
        font-size: 30px;
       }
       .end-image{
        width: 60%;
        height: 400px;
        object-fit: fill;
    }
    .end-points > h3{
        font-size: 26px;
        margin: 20px 0;
    }
    .end-points > ul > li{
        font-size: 22px;
        width: 90%;
        margin: auto;
    }
    .end-points > button{
        width: 80%;
        display: block;
        padding: 10px;
        border: 0;
        font-size: 20px;
    }
    .end-icon{
        font-size: 26px !important;
        font-weight: bolder;
        margin-left: 20px;
    }
    .ended-countdown-heading{
        font-size: 20px;
      }
}
@media(min-width:1000px){
    .end-home-div{
        display: flex;
        align-items: start;
    }
    .end-home-div > img{
        order: 1;
    }
    .end-points{
       
        margin: 0;
        width: 85%;
    }
    .end-home-div > img{
       margin:80px 10px;
       height: 500px;
    }
    
    .end-points > h3{
      margin: 15px;
    }
    .end-icon{
        font-size: 28px !important;
        font-weight: bolder;
        margin-left: 20px;
    }

}

@media(min-width:1400px){
    .end-heading{
        font-size: 36px;
    }
    .end-points > h3{
        font-size: 30px;
    }
    .navigate-links{
        text-decoration: none;
         margin: 5px 20px;
         font-size: 22px;
         font-weight: bolder;
         color:black;
         text-transform: uppercase;
     }
}