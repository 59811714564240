.slick-slider {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.custom-prev-arrow{
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 50%;
    margin: 0 10px;
  }
 .slick-slide{
    display: flex !important;
    justify-content: center !important;
 } 

 
@media(max-width:320px){
   .event-card-final{
    width: 290px !important;
   }
}


 