
  *{
    margin: 0;
    padding: 0;
  }
  .display-container{
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .events-heading{
    text-align: center;
    margin: 15px 0;
    text-transform: uppercase;
  }
  .display-card{
    width: 95%;
    margin:10px auto;
  
    border: 2px solid blue;
    border-radius: 10px;
    display: flex;
    position: relative;
    box-shadow: 0 0 4px 0 grey;
    
  }
  .drag-icon{
    font-size: 35px !important;
    align-self: center;
    height: 50px !important;
  }
  .drag-div{
    border-right: 2px solid blue;
    display: flex;
  }

  .event-icon-div{
    display: flex;
  }

  .event-icon{
    background-color: lightgreen;
    width: 40px !important;
    height: 40px !important;
    padding: 5px;
    color: green;
    border-radius: 50%;
    align-self: center;
    margin: 0 10px;
}

.event-action-div{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    right: 10px;
    height: 100%;
}

.event-details{
    margin: 5px 0;
}
.event-details > p{
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}
.edit:hover{
  color: green;
}
.delete:hover{
    color: red;
}
.noEvents-div{
  width: 90%;
  background-color: whitesmoke;
  margin:0 auto;
  padding:10px 0;
  box-shadow: 0 0 4px 0 gray;
}
.noEvents-div > img{
  width: 90%;
  height: 180px;
  object-fit: contain;
  display: block;
  margin: 10px auto;
 

}

.noEvents-div > h3{
  text-align: center;
  text-transform: uppercase;
}
.noEvents-div > button{
 width: 90%;
 margin:10px auto;
 display: block;
 padding: 5px;
 border: 0;
 border-radius: 10px;
 background-color:#4285F4;
  color: white;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 18px;
}

.newEventButton{
  width: 90%;
  display: block;
  margin: 0 auto; /* Center horizontally */
  position: fixed;
  bottom: 20px;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  padding: 5px;
  border: 0;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  background-color: rgb(92, 130, 253);
  color: white;
}
.group-small{
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 10px;
  color: blue;
  font-size: 14px;
}
.checkbox-div{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}
.single-checkbox-div{
  flex: 1 1 calc(50% - 10px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.single-checkbox-div >input{
  margin-right: 10px;
  width: 16px;
  height: 16px;
}
.single-checkbox-div>label{
  margin-left: 5px;
  font-size: 16px;
}

.tooltip-icon{
  margin-left: 5px;
  width: 25px !important;
  height: 25px !important;
}
.tooltip-inner{
  text-align: left !important;
  padding:5px 10px !important;
}
@media(min-width:425px){
    .drag-icon{
        font-size: 40px !important;
      }
      .event-icon{
        width: 50px !important;
        height: 50px !important;
        padding: 8px;
        color: green;
        margin: 0 15px;
    }
    .event-details >p{
        font-size: 16px;
    }

    
    .noEvents-div > img{
      height: 200px;
    }
    .newEventButton{
      width: 90%;
    font-size: 20px;
    }
    .tooltip-icon{
      margin-left: 8px;
      width: 28px !important;
      height: 28px !important;
    }
    .group-small{
      margin-bottom: 15px;
      font-size: 16px;
    }
 
    .single-checkbox-div >input{
      margin-right: 15px;
      width: 18px;
      height: 18px;
    }
    .single-checkbox-div>label{
      margin-left: 5px;
      font-size: 18px;
    }

}

@media(min-width:768px){
    .display-card{
        width: 80%;
    }
    .drag-icon{
        font-size: 50px !important;
      }
      .event-icon{
        width: 60px !important;
        height: 60px !important;
        padding: 8px;
        color: green;
        margin: 0 15px;
    }
    .event-details >p{
        font-size: 20px;
        margin-left: 15px;
    }
    .action-button{
        font-size: 30px !important;
    }
    .noEvents-div{
      width: 70%;
    }
    .noEvents-div > img{
      height: 250px;
    }
    
    .noEvents-div > h3{
     font-size: 26px;
    }
    .noEvents-div > button{
     width: 70%;
      font-size: 20px;
    }
    .newEventButton{
      width: 70%;
    font-size: 22px;
    margin-top: 20px;
    }
    .group-small{
      margin-bottom: 20px;
      font-size: 16px;
    }
 
    .single-checkbox-div >input{
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }
    .single-checkbox-div>label{
      margin-left: 5px;
      font-size: 20px;
    }
    .tooltip-icon{
      margin-left: 15px;
      width: 30px !important;
      height: 30px !important;
    }

}


@media(min-width:1000px){
    .display-card{
        width: 60%;
    }
    .action-button{
        font-size: 35px !important;
    }
    .event-details > p{
        font-size: 22px;
    }

    .noEvents-div{
      width: 50%;
    }
    .noEvents-div > img{
      height: 250px;
    }
    
    .noEvents-div > h3{
     font-size: 28px;
    }
    .noEvents-div > button{
     width: 60%;
    }
    .newEventButton{
      width: 50%;
    font-size: 24px;
    }
}

@media(min-width:1400px){
  .newEventButton{
    width: 50%;
  font-size: 26px;
  }
}