
.event-form-heading{
      text-align: center;
      margin-top: 20px;
}
.event-form{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 10px;
}

.input-div,.input-div-error{
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
}

.input-field{
    height: 35px;
    width: 100%;
    border: none;
    border-bottom:2px solid silver ;
    outline: none;
}

.input-div-error input{
    border-bottom: 2px solid red;
}
.input-div label{
    position: absolute;
    left: 0;
    bottom: 5px;
    margin-bottom: 5px;
    color: grey;
    pointer-events: none;
    font-weight: bolder;
    transition: all 0.3s ease;
    font-size: 15px;
   } 

   .input-div-error label{
    position: absolute;
    left: 0;
    margin-bottom: 5px;
    color: red;
    pointer-events: none;
    font-weight: bolder;
    transition: all 0.3s ease;
    font-size: 15px;
   }

   .input-field:focus ~ label,
   .input-field:not(:placeholder-shown).input-field:not(:focus) ~ label
   {
       transform: translateY(-25px);
       color: blue;
       font-weight: bolder;
   }

   .input-div-error .input-field:focus ~ label,
.input-div-error .input-field:not(:placeholder-shown).input-field:not(:focus) ~ label
{
    transform: translateY(-25px);
    color: red;
    font-weight: bolder;
}

   .input-field:focus ~ label .label-hide,
   .input-field:not(:placeholder-shown).input-field:not(:focus) ~ label .label-hide
   {
      display: none;
   }
   
   .date-input{
    width: 100% !important;
    border: 0;
    border:2px solid rgb(164, 164, 164) ;
    height: 55px;   
    border-radius: 5px;
    padding: 10px;
    color: black;
    background-color: transparent;
}

 .time-input{
   /* margin-bottom: 15px !important;
   margin-top: 10px !important;
    */
   margin-top: 10px !important;
 }

 

 .event-submit-button{
    outline: 0;
    border: 0;
    padding: 10px;
    border-radius: 10px;
    width: 95%;
    margin: 15px auto;
    background-color: blue;
    color: white;
    font-size: 18px;
    font-weight: bolder;
 }

 .event-container{
    border: 2px solid blue;
    border-radius: 10px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;

 }

 .textarea-error{
   border: 1px solid red;
   resize: none;
 }
 .info-textarea{
    resize: none;
 }

 @media(min-width:1200px){
    .time-input{
        width: 100% !important;
        
    }

 }